import ErrorBoundary from '../ErrorBoundary'
import React from 'react'

interface SuspenseWithBoundaryProps extends React.PropsWithChildren {
  fallback?: React.ReactNode
  errorView?: React.ReactNode
}

function SuspenseWithBoundary({
  children,
  errorView,
  fallback,
}: SuspenseWithBoundaryProps) {
  return (
    <React.Suspense fallback={fallback}>
      <ErrorBoundary errorView={errorView}>{children}</ErrorBoundary>
    </React.Suspense>
  )
}

export default SuspenseWithBoundary
