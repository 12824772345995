import uploadFiles from './upload-files'
import { v4 as uuidV4 } from 'uuid'

function getMediaType(mimeType: string): MediaType {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
  const type = mimeType.split('/')[0]

  if (['image', 'video'].includes(type)) {
    return type as MediaType
  }

  return 'other'
}

async function uploadAttachedFiles(files: File[]): Promise<Media[]> {
  const fileUrls = await uploadFiles(files)

  const media: Media[] = []

  let i = 0
  for (const file of files) {
    media.push({
      name: file.name,
      type: getMediaType(file.type),
      url: fileUrls[i],
    })
    i++
  }

  return media
}

async function runPendingFunctions(
  reply: ReplyProps
): Promise<Partial<Message> | undefined> {
  if (!reply.files?.length) {
    return
  }

  return { files: await uploadAttachedFiles(reply.files) }
}

/**
 * Starts uploading files and returns a `PendingMessage` with a pending field
 * which can be awaited for when the files are done uploading. The current implmentation
 * is open to allowing to have many different _pendable_ actions that return part of
 * the `Message` field
 */
function pendReply(reply: ReplyProps): PendingMessage {
  const pending = runPendingFunctions(reply)

  return {
    createdAt: new Date(),
    id: uuidV4(),
    pending,
    reply,
  }
}

export default pendReply
