import * as lazyRoutes from './lazy-routes'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import React from 'react'
import RedirectToNextMeeting from './pages/meet/redirect-to-next-meeting'
import RepositoryEmptyView from './components/RepositoryEmptyView'
import bellboy from './lib/bell-boy'
import { useTheme } from '@blackmann/wardrobe'
import MeetingEmptyView from './components/MeetingEmptyView'

function AppRoutes() {
  const { setTheme } = useTheme()
  const current_location = useLocation()
  bellboy.navigate = useNavigate()
  const nav = useNavigate()

  const presistChatURL = (location: any) => {
    //This function is created as a quick solution to presist chat url
    //There are better ways to do this but will need to re-implement routes
    //You can make use of useNavigation,useLocation and useNavigate hooks to handle this in a better way
    const isChatOpen = location.pathname.includes('chat')
    if (isChatOpen) {
      const chatUrl = sessionStorage.getItem('chat_url')
      const preUrl = sessionStorage.getItem('prev_url')
      if (chatUrl && chatUrl !== '/chat' && !preUrl?.includes('chat')) {
        nav(chatUrl)
      }
      sessionStorage.setItem('chat_url', location.pathname)
    }
    sessionStorage.setItem('prev_url', location.pathname)
  }

  React.useEffect(() => {
    const userSelectedTheme = localStorage.getItem('theme')
    if (userSelectedTheme) {
      setTheme(
        userSelectedTheme === 'system'
          ? 'system'
          : userSelectedTheme === 'dark'
          ? 'dark'
          : 'light'
      )
    } else {
      setTheme('system')
    }
    //To presist chat url
    presistChatURL(current_location)
  }, [setTheme, current_location])

  return (
    <Routes>
      <Route element={<lazyRoutes.Login />} path="login" />
      <Route element={<AppLayout />} path="">
        <Route element={<lazyRoutes.Chat />} path="chat">
          <Route element={<Navigate replace to="unreads" />} path="" />
          <Route element={<lazyRoutes.GroupSearch />} path="groups/search" />
          <Route
            element={<lazyRoutes.MessagesSearch />}
            path="messages/search"
          />
          <Route element={<lazyRoutes.DMChat />} path="dm/:conversationId" />
          <Route
            element={<lazyRoutes.GroupChat />}
            path="group/:conversationId"
          />
          <Route element={<lazyRoutes.StarredMessages />} path="starred" />
          <Route element={<lazyRoutes.UnreadChats />} path="unreads" />
          <Route element={<Navigate replace to="" />} path="*" />
        </Route>

        <Route element={<lazyRoutes.Feeds />} path="feeds">
          <Route element={<lazyRoutes.FeedDetail />} path=":feedId" />
          <Route element={<lazyRoutes.StarredPosts />} path="starred" />
          <Route element={<lazyRoutes.GroupFeeds />} path="group/:groupId" />
          <Route element={<lazyRoutes.GroupFeeds />} path="" />
        </Route>

        <Route element={<lazyRoutes.Meet />} path="meet">
          <Route element={<lazyRoutes.MeetDetail />} path=":meetId" />
          <Route element={<lazyRoutes.MeetEdit />} path=":meetId/edit" />
          <Route element={<lazyRoutes.NewMeeting />} path="new" />
          <Route element={<MeetingEmptyView />} path="" />

          <Route element={<RedirectToNextMeeting />} path="*" />
        </Route>

        <Route element={<lazyRoutes.Repo />} path="repository">
          <Route element={<lazyRoutes.GroupRepo />} path="group/:groupId/*" />
          <Route element={<lazyRoutes.FileEditor />} path="file/:fileId/*" />
          <Route element={<Navigate replace to="empty" />} path="" />
          <Route
            element={
              // TODO: Show recently accessed files
              <RepositoryEmptyView />
            }
            path="*"
          />
        </Route>
        <Route element={<lazyRoutes.UserProfile />} path="user-profile" />
        <Route element={<Navigate to="chat" />} path="" />
      </Route>
    </Routes>
  )
}

export default AppRoutes
