const DEFAULT_EXPIRY_HOURS = 12 * 60 * 60 * 1000 // 12 hours
const REFRESH_THRESHOLD = 2_000

interface CacheOptions {
  // time to expire in milliseconds. Example: 1hr = 3_600 milliseconds
  expires?: number
  version?: string
}

function localStorageCache(cacheName: string, options?: CacheOptions) {
  const { expires = DEFAULT_EXPIRY_HOURS, version = '1' } = options || {}

  const cacheVersionKey = `${cacheName}-version`
  const cacheExpiryKey = `${cacheName}-expiry`

  const currentVersion = localStorage.getItem(cacheVersionKey) || version
  const expiryDate =
    parseInt(localStorage.getItem(cacheExpiryKey) || '0') || Date.now()

  if (currentVersion !== version || Date.now() >= expiryDate) {
    localStorage.removeItem(cacheName)
  }

  // we will reset the cache if it's a page quick refresh
  const lastUnloadMilliseconds = parseInt(
    localStorage.getItem('last-unload') || '0'
  )

  // even if lastUnloadMilliseconds is 0, the time difference will be huge anyways
  const lastUnloadTime = new Date(lastUnloadMilliseconds)

  let map: Map<any, any>
  if (Date.now() - lastUnloadTime.getTime() < REFRESH_THRESHOLD) {
    map = new Map()
  } else {
    map = new Map(JSON.parse(localStorage.getItem(cacheName) || '[]'))
  }

  window.addEventListener('beforeunload', () => {
    const cache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem(cacheName, cache)
    localStorage.setItem(cacheVersionKey, version)
    localStorage.setItem(cacheExpiryKey, JSON.stringify(Date.now() + expires))

    localStorage.setItem('last-unload', Date.now().toString())
  })

  return map
}

export default localStorageCache
