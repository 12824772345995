import React from 'react'
import styles from './IndeterminateProgress.module.css'

function IndeterminateProgress() {
  return (
    <div className={styles.progress}>
      <div className={styles.progressValue}></div>
    </div>
  )
}

export default IndeterminateProgress
