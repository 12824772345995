import React from 'react'
import styles from './SnackbarContext.module.css'

interface Snack {
  id: string
  content: React.ReactNode
  timeout?: number
  timeoutId?: ReturnType<typeof setTimeout>
}

interface SnackContextValue {
  snacks: Array<Snack>
  setSnacks(fn: (previous: Array<Snack>) => Array<Snack>): void
}

interface SnackProviderProps extends React.PropsWithChildren {}

const SnackContext = React.createContext<SnackContextValue | null>(null)

function SnackbarProvider({ children }: SnackProviderProps) {
  const [snacks, setSnacks] = React.useState<Array<Snack>>([])

  React.useEffect(() => {
    // TODO: Limit active snacks
    for (const snack of snacks) {
      if (!snack.timeoutId && snack.timeout) {
        snack.timeoutId = setTimeout(() => {
          setSnacks((prev) =>
            prev.filter((snackCandidate) => snackCandidate.id !== snack.id)
          )
        }, snack.timeout)
      }
    }
  }, [snacks])

  return (
    <SnackContext.Provider value={{ setSnacks, snacks }}>
      {children}

      <div className={styles.snacks}>
        {snacks.map((snack) => (
          <React.Fragment key={snack.id}>{snack.content}</React.Fragment>
        ))}
      </div>
    </SnackContext.Provider>
  )
}

export { SnackContext, SnackbarProvider }

export type { Snack }
