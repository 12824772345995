import React from 'react'
import styles from './EmptyView.module.css'

interface EmptyViewProps extends React.PropsWithChildren {
  icon: React.ReactNode
}

function EmptyView({ children, icon }: EmptyViewProps) {
  return (
    <div className={styles.emptyView}>
      <div className={styles.illustration}>{icon}</div>
      <div className={styles.title}>{children}</div>
    </div>
  )
}

export default EmptyView
