import { Navigate, Outlet } from 'react-router-dom'
import AppSuspense from '../components/AppSuspense'
import { ChatProvider } from '../contexts/ChatContext'
import NavigationTabs from '../components/NavigationTabs'
import React from 'react'
import RequireAuthentication from '../components/RequireAuthentication'
import { SavedFeedProvider } from '../contexts/SavedFeed'
import SuspenseWithBoundary from '../components/SuspenseWithError'
import { UserStatusProvider } from '../contexts/UsersStatusContext'
import styles from './AppLayout.module.css'
import useAuth from '../hooks/use-auth'
import { StarredContextProvider } from '../contexts/StarredContext'
import { AxiosInterceptor } from '../requests'
import FeedContext from '../contexts/FeedContext'
import UserProfileStatus from '../contexts/UserProfileStatus'
import OtherTenantNotifier from '../components/OtherTenantNotifier'
import UpComingMeetingNotifier from '../components/UpcommingMeetingNotifer'

function AppLayout() {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to="/login" />
  }

  return (
    <RequireAuthentication>
      <AxiosInterceptor>
        <OtherTenantNotifier>
          <FeedContext>
            <UserStatusProvider>
              {/* <UserProfileStatus> */}
              <StarredContextProvider>
                <ChatProvider>
                  <UpComingMeetingNotifier>
                    <main className={styles.main}>
                      <div className={styles.navWrapper}>
                        <NavigationTabs />
                      </div>

                      <div className={styles.outlet}>
                        <SuspenseWithBoundary fallback={<AppSuspense />}>
                          <Outlet />
                        </SuspenseWithBoundary>
                      </div>
                    </main>
                  </UpComingMeetingNotifier>
                </ChatProvider>
              </StarredContextProvider>
              {/* </UserProfileStatus> */}
            </UserStatusProvider>
          </FeedContext>
        </OtherTenantNotifier>
      </AxiosInterceptor>
    </RequireAuthentication>
  )
}

export default AppLayout
