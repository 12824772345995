import axios from 'axios'
import endpoints from './endpoints'
import requests from '../requests'
import { axiosWithNoAuth } from '../utils'


async function upload(file: File, url: string) {
  await axiosWithNoAuth.put(url, file)

  // AWS S3 upload urls come with some signing params
  // We're returning a stripped version
  return url.split('?')[0]
}

async function uploadFiles(files: Array<File>, fileName?: string) {
  const filesOptions = files.map((file) => ({
    contentType: file.type,
    name: file.name,
  }))

  const { data: uploadUrls } = await requests.post(endpoints.utils, {
    files: filesOptions,
    fn: 'signS3Upload',
    fileName, // will update this file in files
  })

  return await Promise.all(
    files.map((file, index) => upload(file, uploadUrls[index]))
  )
}

export default uploadFiles
