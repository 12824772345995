import endpoints, { generateEndpoint } from '../lib/endpoints'
import { getPreference, setPreference } from '../lib/preferences'
import React from 'react'
import requests from '../requests'
import updateRequests from '../lib/update-requests'

type Profiles = Record<string, Auth>

interface AuthValue {
  /** This is the slug of the current auth's organisation. `auth.organization.slug` */
  activeProfile: string | null
  profiles: Profiles
  setActiveProfile: (profile: string | null) => void
  setProfiles: (profiles: Profiles) => void
}

const AuthContext = React.createContext<AuthValue>({
  get activeProfile() {
    console.error('Missing `AuthProvider`')
    return null
  },
  get profiles() {
    console.error('Missing `AuthProvider`')
    return {}
  },
  setActiveProfile() {
    console.error('Missing `AuthProvider`')
  },
  setProfiles() {
    console.error('Missing `AuthProvider`')
  },
})

interface AuthProviderProps extends React.PropsWithChildren {}

function AuthProvider({ children }: AuthProviderProps) {
  const [activeProfile, setActiveProfile] = React.useState<string | null>(null)
  const [profiles, setProfiles] = React.useState<Profiles>({})

  const [initialized, setInitialized] = React.useState(false)
  const [updated, setUpdated] = React.useState(false)

  const currentAuth = activeProfile ? profiles[activeProfile] : undefined

  const updateProfile = React.useCallback(async () => {
    if (!currentAuth || !activeProfile || updated) {
      return
    }

    const { user } = currentAuth
    const endpoint = generateEndpoint(endpoints.user, { user: user._id })
    const { data: updatedUser } = await requests.get(endpoint)

    setUpdated(true)

    setProfiles((profiles) => ({
      ...profiles,
      [activeProfile]: { ...currentAuth!, user: updatedUser },
    }))
  }, [activeProfile, currentAuth, updated])

  React.useEffect(() => {
    const activeProfile = getPreference<string, null>('activeProfile', null)
    setActiveProfile(activeProfile)

    const profiles = getPreference<Profiles, Profiles>('profiles', {})
    setProfiles(profiles)

    setInitialized(true)
  }, [])

  React.useEffect(() => {
    if (!initialized) {
      return
    }

    setPreference('activeProfile', activeProfile)
    setPreference('profiles', profiles)
  }, [initialized, activeProfile, profiles])

  React.useEffect(() => {
    updateProfile()
  }, [updateProfile])

  updateRequests(currentAuth)

  return (
    <AuthContext.Provider
      value={{
        activeProfile,
        profiles,
        setActiveProfile,
        setProfiles,
      }}
    >
      {initialized ? children : null}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }

export type { Profiles }
