import endpoints, { generateEndpoint } from './endpoints'
import bellboy from './bell-boy'
import db from './db'
import getMessageLink from './get-message-link'
import pendReply from './pend-reply'
import realtime from './realtime'
import requests from '../requests'
import useAuth from '../hooks/use-auth'
import Ws from './ws'
const expandQuery = [
  '$populate[]=author',
  '$populate[]=parentMessage',
  '$sort[createdAt]=-1',
].join('&')

interface ChatOptions {
  author: User
  conversation: Conversation
}
export class Chat {
  author: User
  conversation: Conversation

  private realtimeTopic: string

  private listeners: Array<VoidFunction> = []

  private subscribed = false
  private active = false

  private page = {
    reachedBottom: false,
    reachedTop: false,
  }

  quotedMessage?: Message
  status: RequestStatus = 'idle'

  pendingMessages: Array<PendingMessage> = []

  unreadCount = 0

  constructor({ author, conversation }: ChatOptions) {
    this.author = author
    this.conversation = conversation
    this.unreadCount = conversation.stats?.unread ?? 0

    this.realtimeTopic = `conversations/${this.conversation._id}`
  }

  addListener(listener: VoidFunction): VoidFunction {
    if (!this.listeners.includes(listener)) {
      this.listeners.push(listener)
    }

    return () => this.removeListener(listener)
  }

  // TODO: Accept reason, like removed, left-page, etc.
  // Based on reason, we may need to clear the indexdb of all messages
  // for this conversation
  close() {
    realtime.unsubscribe(this.realtimeTopic, this)
  }

  async fetchNext() {
    await this.fetch(await this.getNextEndpoint(), true)
  }

  async fetchPrevious() {
    if (this.page.reachedTop || this.status === 'in-progress') {
      return
    }

    await this.fetch(await this.getPreviousEndpoint(), false)
  }

  private async fetch(endpoint: string, next: boolean) {
    // remember to trigger a go-live
    this.goLive()

    this.status = 'in-progress'
    this.notifyListeners()

    const { data } = await requests.get(endpoint)
    const { data: newMessages } = data

    this.status = 'idle'

    if (!newMessages.length) {
      this.notifyListeners()
      return
    }

    newMessages.reverse()

    if (newMessages.length === 0) {
      if (next) {
        this.page.reachedBottom = true
      } else {
        this.page.reachedTop = true
      }
    }

    await db.messages.bulkPut(newMessages)
    this.status = 'idle'

    this.notifyListeners()
  }

  quote(message?: Message) {
    this.quotedMessage = message
    this.notifyListeners()
  }

  async read() {
    const lastMessage = await this.getLastMessage()
    const date =
      lastMessage &&
      lastMessage.createdAt &&
      typeof lastMessage.createdAt === 'string'
        ? new Date(lastMessage.createdAt)
        : new Date()

    // TOOD: We should use ws for this
    await requests.post(endpoints.lastSeen, {
      conversation: this.conversation._id,
      date,
      user: this.author._id,
    })

    this.unreadCount = 0

    this.notifyListeners()
  }

  async star(message: Message, star: boolean = true) {
    const alreadyStarred = message.starredBy?.includes(this.author._id)

    if ((alreadyStarred && star) || (!alreadyStarred && !star)) {
      return
    }

    const base = generateEndpoint(endpoints.message, {
      message: message._id,
    })

    const endpoint = [base, expandQuery].join('?')

    let starredBy = [...(message.starredBy || [])]
    if (star) {
      starredBy.push(this.author._id)
    } else {
      starredBy = starredBy.filter((star) => star !== this.author._id)
    }
    const { data } = await requests.patch(endpoint, { starredBy })
    const newMessage = data as Message

    await db.messages.update(message, newMessage)

    this.notifyListeners()
  }

  reply(replyData: ReplyProps) {
    replyData.quotedMessage = this.quotedMessage
    this.quotedMessage = undefined
    this.notifyListeners()

    this.queue(replyData)
  }

  private queue(replyData: ReplyProps) {
    this.pendingMessages = [...this.pendingMessages, pendReply(replyData)]
    this.notifyListeners()

    // if this is the only pending message at the moment, let's
    // reactivate `sendNext`
    if (this.pendingMessages.length === 1) {
      this.sendNext()
    }
  }

  private async sendNext() {
    const [nextMessage] = this.pendingMessages

    if (!nextMessage) {
      return
    }

    const pendingFields = await nextMessage.pending

    const message = {
      conversation: this.conversation._id,
      elements: nextMessage.reply.elements,
      mentions: nextMessage.reply.mentions,
      parentMessage: nextMessage.reply.quotedMessage?._id,
      text: nextMessage.reply.text,
      ...pendingFields,
    }

    realtime.send(this.realtimeTopic, message)

    // const endpoint = `${endpoints.messages}?${expandQuery}`
    // await requests.post(endpoint, message)
    this.pendingMessages.shift()
    this.pendingMessages = [...this.pendingMessages]

    this.notifyListeners()

    this.sendNext()
  }

  removeListener(listener: VoidFunction) {
    const index = this.listeners.indexOf(listener)
    this.listeners.splice(index, 1)
  }

  static getMessagesQuery(conversation: Conversation) {
    return db.messages.where({ conversation: conversation._id })
  }

  async getMessages() {
    return await Chat.getMessagesQuery(this.conversation).sortBy('createdAt')
  }

  private async getFirstMessage() {
    const count = await Chat.getMessagesQuery(this.conversation).count()

    if (!count) {
      return
    }

    return (await this.getMessages())[0]
  }

  private async getLastMessage() {
    const count = await Chat.getMessagesQuery(this.conversation).count()

    if (!count) {
      return
    }

    return (await this.getMessages())[count - 1]
  }

  private async getNextEndpoint() {
    const segments = [this.getEndpoint()]

    const lastMessage = await this.getLastMessage()
    if (lastMessage) {
      const date = new Date(lastMessage.createdAt)
      segments.push(`createdAt[$gt]=${date.getTime()}`)
    }

    return segments.join('&')
  }

  private async getPreviousEndpoint() {
    const segments = [this.getEndpoint()]

    const firstMessage = await this.getFirstMessage()

    if (firstMessage) {
      const date = new Date(firstMessage.createdAt)
      segments.push(`createdAt[$lt]=${date.getTime()}`)
    }

    return segments.join('&')
  }

  private notifyListeners() {
    for (const listener of this.listeners) {
      listener()
    }
  }

  async catchUp() {
    await this.fetchNext()
  }

  setActive(active: boolean) {
    this.active = active
  }

  goLive() {
    if (this.subscribed) {
      return
    }

    realtime.subscribe(this.realtimeTopic, this)

    this.subscribed = true
  }

  // Realtime handlers
  onClose() {}

  onError() {}

  async onMessage(message: RealtimeMessage<Message>) {
    if (message.type === 'message') {
      db.messages.put(message.data)
      if (!this.active) {
        this.unreadCount++
        this.notifyListeners()
      }
      if (!this.active && message.data.author._id !== this.author._id) {
        const messageDetails = message.data as Message<Conversation>
        const { data: conversationDetail } = await requests.get(
          `conversations/${messageDetails.conversation}`
        )
        let title = `${conversationDetail.title} - ${messageDetails.tenant?.title}`
        if (conversationDetail.type === 'dm') {
          title = `${messageDetails.tenant?.title}`
        }
        bellboy.sendNotification(title, {
          body: `${messageDetails.author.firstName} ${
            messageDetails.author.lastName
          }: ${messageDetails.text?.substring(0, 13)}...`,
          data: {
            destination: getMessageLink(messageDetails, this.conversation),
          },
        })
      }
    }
  }

  // End: Realtime handlers

  private getEndpoint() {
    const base = endpoints.messages

    return `${base}?conversation=${this.conversation._id}&${expandQuery}`
  }
}

export default Chat
