import { Chat } from '../lib/chat'
import React from 'react'
import axios from 'axios'
import endpoints from '../lib/endpoints'
import queries from '../lib/queries'
import resolveBaseUrl from '../lib/resolve-base-url'
import useAuth from '../hooks/use-auth'
import useFind from '../hooks/use-find'
import useLookup from '../hooks/use-lookup'
import realtime from '../lib/realtime'

interface Unread {
  conversation: Conversation
  messages: Message[]
}

interface ChatContextValue {
  chats: Chat[]
  unreads: Unread[]
  setChats: (chats: Chat[]) => void
}

const ChatContext = React.createContext<ChatContextValue>({
  get chats() {
    console.error('Missing `ChatContext`')
    return []
  },

  get unreads() {
    console.error('Missing `ChatContext`')
    return []
  },
  setChats(chats) {},
})

interface ChatProviderProps extends React.PropsWithChildren {}

function ChatProvider({ children }: ChatProviderProps) {
  const { user } = useAuth()
  const { data: conversationsData, mutate } = useFind(
    ...queries.allConversations(user!._id)
  )
  const { data: unreads, mutate: unreadMutate } = useFind(endpoints.unreads)
  const [chats, setChats] = React.useState<Chat[]>([])

  const conversations = conversationsData as Conversation[]
  const conversationsLookup = useLookup(conversations)

  React.useEffect(() => {
    // We need to synchronize the chats. Some people may have left the chat or
    // get added to a new one, or a conversation was archived.
    // Diff:
    // So we first remove/end the chats that are missing from the new conversations
    // list.
    // Then we create new chats from new conversations that appear.
    setChats((chats) => {
      const newChats: Chat[] = []
      const existingConversationChats: Record<string, true> = {}

      for (const chat of chats) {
        if (conversationsLookup[chat.conversation._id]) {
          newChats.push(chat)
          existingConversationChats[chat.conversation._id] = true
        } else {
          // this chat is over (any of the reasons mentioned above)
          chat.close()
        }
      }

      for (const conversation of conversations) {
        if (!existingConversationChats[conversation._id]) {
          const chat = new Chat({ author: user!, conversation: conversation })
          chat.goLive()
          newChats.push(chat)
        }
      }

      return newChats
    })

    chats.forEach((chat) => {
      realtime.subscribe((chat as any).realtimeTopic, {
        onClose() {},
        onError() {},
        onMessage(message: RealtimeMessage<Message>) {
          if (message.type === 'message') {
            if (message.data.author._id !== user?._id) unreadMutate()
            const updatedChats = chats.filter(
              (el) => el.conversation._id !== message.data.conversation
            )
            const updatedChat = chats.find(
              (el) => el.conversation._id === message.data.conversation
            )
            if (updatedChat) {
              if (
                updatedChat.conversation.stats &&
                updatedChat.conversation.stats.lastMessage
              ) {
                updatedChat.conversation.stats.lastMessage = {
                  createdAt: message.data.createdAt,
                  text: message.data.text!,
                }
                setChats([...updatedChats, updatedChat])
              }
            }
          }
        },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, conversationsLookup, user])

  return (
    <ChatContext.Provider value={{ chats, setChats, unreads }}>
      {children}
    </ChatContext.Provider>
  )
}

export { ChatContext, ChatProvider }
