import React from 'react'
import queries from '../lib/queries'
import useAuth from '../hooks/use-auth'
import useFind from '../hooks/use-find'

const StarredContext = React.createContext<{
  starredCount: number
  addCount(count: number): void
}>({
  addCount: (count: number) => {},
  starredCount: 0,
})
const StarredContextProvider = ({ children }: React.PropsWithChildren) => {
  const { user } = useAuth()
  const { data } = useFind(...queries.starredMessages(user!))
  const [starredCount, setStarredCount] = React.useState(0)

  React.useEffect(() => {
    if (!data.data) return
    setStarredCount(data.data.length)
  }, [data.data])

  function addCount(count: number) {
    setStarredCount(starredCount + count)
  }

  return (
    <StarredContext.Provider value={{ addCount, starredCount }}>
      {children}
    </StarredContext.Provider>
  )
}

export { StarredContext, StarredContextProvider }
