import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import EmptyView from './EmptyView'
import React from 'react'
import styles from './RepositoryEmptyView.module.css'

function RepositoryEmptyView() {
  return (
    <EmptyView icon={<ArchiveBoxXMarkIcon />}>
      <div className={styles.text}>Click on a group to view files</div>
    </EmptyView>
  )
}

export default RepositoryEmptyView
