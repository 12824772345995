import dayjs from 'dayjs'
import endpoints from './endpoints'

interface FilesQueryOptions {
  group: string
  parent?: string | Object
  _id?: string
  name?: string
}

interface FilesQueryDraftOption {
  group: string
}

function dmConversations(userId: string) {
  return [
    endpoints.conversations,
    { $populate: 'users', '$sort[title]': 1, type: 'dm', 'users[$in]': userId },
  ] as const
}

function getUser(userId: string){
  return [endpoints.users + `/${userId}`, {}] as const
}

function filesQuery(options: FilesQueryOptions) {
  return [
    endpoints.files,
    {
      $populate: 'owner editingUser lastModifiedBy',
      ...options,
    },
  ] as const
}

function groupAllSearchQuery(options: FilesQueryOptions) {
  return [
    endpoints.fileUpdate,
    {
      $populate: 'owner editingUser lastModifiedBy',
      action: 'getGroupAllDocument',
      ...options,
    },
  ] as const
}

function groupAllDraftSearchQuery(options: FilesQueryOptions) {
  return [
    endpoints.fileUpdate,
    {
      $populate: 'owner editingUser lastModifiedBy',
      action: 'searchGroupDraft',
      ...options,
    },
  ] as const
}

function draftQuery(options: FilesQueryDraftOption) {
  return [
    endpoints.fileUpdate,
    {
      $populate: 'owner editingUser lastModifiedBy',
      action: 'getGroupDraftDocument',
      ...options,
    },
  ] as const
}

function groupConversations(userId: string) {
  const query: Record<string, string> = {
    $populate: 'group',
    '$sort[title]': '1',
    type: 'group',
    user: userId,
  }
  return [endpoints.conversations, query] as const
}

function conversationMedia(conversationId: string, limit?: number) {
  const query: Record<string, string> = {
    conversation: conversationId,
    '$populate[0]': 'author',
    '$populate[1]': 'conversation',
    '$sort[createdAt]': '-1',
    'files.type[$in][0]': 'image',
    'files.type[$in][1]': 'video',
    '$populate':'conversation.users'
  }

  if (limit) {
    query['$limit'] = limit.toString()
  }

  return [endpoints.messages, query] as const
}

function conversationFiles(conversationId: string, limit?: number) {
  const query: Record<string, string> = {
    $populate: 'author',
    '$sort[createdAt]': '-1',
    conversation: conversationId,
    'files.type[$in][0]': 'document',
    'files.type[$in][1]': 'other',
  }

  if (limit) {
    query['$limit'] = limit.toString()
  }

  return [endpoints.messages, query] as const
}

function allConversations(userId: string) {
  const query: Record<string, string> = {
    '$populate[0]': 'group',
    '$populate[1]': 'users',
    '$sort[title]': '1',
    $stats: 'true',
    user: userId,
  }
  return [endpoints.conversations, query] as const
}

function groupMemberships(userId: string) {
  return [endpoints.groupMembers, { $populate: 'group', user: userId,'$sort[createdAt]': '-1' }] as const
}

function groupJoinRequests(userId: string) {
  return [endpoints.groupJoins, { status: 'requested', user: userId }] as const
}

function meetings(user: User) {
  const topOfTheHour = dayjs().startOf('minute').subtract(0, 'minute')
  return [
    endpoints.meetings,
    {
      '$or[0][invitedUsers][$in]': user._id,
      '$or[1][host]': user._id,
      '$sort[meetingDate]': 1,
      'endDate[$gt]': topOfTheHour.toISOString(),
      'status[$ne]': 'ended',
    },
  ] as const
}

function starredMessages(user: User) {
  return [
    endpoints.messages,
    {
      '$populate[0]': 'author',
      '$populate[1]': 'parentMessage.author',
      '$populate[2]': 'conversation',
      '$sort[createdAt]': '-1',
      'starredBy[$in]': user._id,
      '$populate':'conversation.users'
    },
  ] as const
}

function savedPosts(user: User) {
  return [
    endpoints.postInteractions,
    {
      '$populate[0]': 'post',
      '$populate[1]': 'post.group',
      liked: true,
      user: user._id,
    },
  ] as const
}

const queries = {
  allConversations,
  conversationFiles,
  conversationMedia,
  dmConversations,
  draftQuery,
  filesQuery,
  groupAllDraftSearchQuery,
  groupAllSearchQuery,
  groupConversations,
  groupJoinRequests,
  groupMemberships,
  getUser,
  meetings,
  savedPosts,
  starredMessages,
}

export default queries
