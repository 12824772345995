import requests from '../requests'

async function fetcher(path: string, queryParams: Record<string, any> = {}) {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParams)) {
    if (['', undefined].includes(value)) {
      continue
    }

    params.append(key, value)
  }

  const endpoint = [path, params.toString()].filter((p) => p.length).join('?')
  const { data } = await requests.get(endpoint)

  return data
}

export default fetcher
