function resolveBaseUrl(tenantId: string) {
  const url = process.env.REACT_APP_API_URL
  if (url?.includes('localhost')) {
    return url
  }

  return url?.replace(/(https?:\/\/)/, `$1${tenantId}.`)
}

export default resolveBaseUrl
