import React, { useState } from 'react'
import axios from 'axios'
import endpoints from '../lib/endpoints'
import resolveBaseUrl from '../lib/resolve-base-url'
import useAuth from '../hooks/use-auth'
import bellboy from '../lib/bell-boy'
import getMessageLink from '../lib/get-message-link'
import { getFeedLink } from '../contexts/FeedContext'

const OtherTenantNotifier = ({ children }: { children: JSX.Element }) => {
  const { profiles, organization } = useAuth()
  const [posts, setPosts] = React.useState<Post[]>([])
  const [messages, setMessages] = React.useState<any[]>([])
  const [websocketConntections, setWebsocketConntections] = useState<
    Array<string>
  >([])

  async function loadChats() {
    for (let profile in profiles) {
      if (organization?.slug !== profiles[profile].organization.slug) {
        const url = resolveBaseUrl(profiles[profile].organization.slug)
        axios.defaults.baseURL = url
        const query: Record<string, string> = {
          '$populate[0]': 'group',
          '$populate[1]': 'users',
          '$sort[title]': '1',
          $stats: 'true',
          user: profiles[profile].user._id,
        }
        axios.defaults.headers[
          'authorization'
        ] = `Bearer ${profiles[profile].token}`
        const { data } = await axios.get(endpoints.conversations, {
          params: query,
        })
        const groupFeedQuery: Record<string, string> = {
          $populate: 'group',
          '$sort[createdAt]': '-1',
          user: profiles[profile].user._id,
        }
        axios.defaults.headers[
          'authorization'
        ] = `Bearer ${profiles[profile].token}`
        const { data: newMembers } = await axios.get(endpoints.groupMembers, {
          params: groupFeedQuery,
        })

        data.forEach((el: any) => {
          const conversationId = `conversations/${el._id}`
          const isConnectionAlreadyExist = websocketConntections.find(
            (el) => el === conversationId
          )
          if (isConnectionAlreadyExist) return
          setWebsocketConntections((prev) => [...prev, conversationId])
          function startWebsocket() {
            let wsUrl = url?.replace('https', 'wss')
            wsUrl = wsUrl + '/' + profiles[profile].token
            let ws = new WebSocket(wsUrl)

            ws.onopen = () => {
              ws.send(
                JSON.stringify({ topic: conversationId, type: 'subscribe' })
              )
            }
            ws.onmessage = async (message) => {
              const data = JSON.parse(message.data)
              if (data.data) {
                const isMessageExist = messages.find(
                  (el2) => el2._id === el._id
                )
                if (!isMessageExist) {
                  setMessages((prevMessages) => [...prevMessages, data.data])
                  const messageDetails = data.data as any
                  const { data: conversationDetail } = await axios.get(
                    `conversations/${messageDetails.conversation}`
                  )
                  let title = `${conversationDetail.title} - ${messageDetails.tenant?.title}`
                  if (messageDetails.conversation.type === 'dm') {
                    title = `${messageDetails.tenant?.title}`
                  }
                  bellboy.sendNotification(title, {
                    body: `${messageDetails.author.firstName} ${
                      messageDetails.author.lastName
                    }: ${messageDetails.text?.substring(0, 24)}`,
                    data: {
                      destination: getMessageLink(
                        messageDetails,
                        conversationDetail
                      ),
                      tenant: messageDetails.tenant?.slug,
                    },
                  })
                }
              }
            }
            ws.onclose = () => {
              ws = null as any
              startWebsocket()
            }
          }
          startWebsocket()
        })

        newMembers.data.forEach((el: any) => {
          let wsUrl = url?.replace('https', 'wss')
          wsUrl = wsUrl + '/' + profiles[profile].token
          let ws = new WebSocket(wsUrl)
          const feedId = `feed/${el.group?._id}`

          ws.onopen = () => {
            ws.send(JSON.stringify({ topic: feedId, type: 'subscribe' }))
          }
          ws.onmessage = async (message) => {
            const data = JSON.parse(message.data)
            if (data.data) {
              const post = data.data
              const isPostExist = posts.find((el) => el._id === post._id)
              if (!isPostExist) {
                setPosts((prevPosts) => [...prevPosts, post])

                return bellboy.sendNotification(
                  `${post.group.title} - ${post.tenant?.title || ''}`,
                  {
                    body:
                      'New content is available: ' +
                      post.title?.substring(0, 24),
                    data: {
                      destination: getFeedLink(post),
                      tenant: post.tenant?.slug,
                    },
                  }
                )
              }
            }
          }
          ws.onclose = () => {}
        })
      }
    }
  }

  React.useEffect(() => {
    loadChats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, profiles])

  return children
}

export default OtherTenantNotifier
