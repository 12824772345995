import { Navigate } from 'react-router-dom'
import React from 'react'
import useAuth from '../hooks/use-auth'

interface RequireAuthenticationProps extends React.PropsWithChildren {}

function RequireAuthentication({ children }: RequireAuthenticationProps) {
  const { user, organization } = useAuth()
  if (!user || organization?.status !== 'active') {
    return <Navigate replace to="/login" />
  }

  return <>{children}</>
}

export default RequireAuthentication
