export function getPreference<T, D>(key: string, defaultValue: D) {
  const pref = localStorage.getItem(key)

  switch (true) {
    case typeof pref === 'string': {
      return JSON.parse(pref!) as T
    }

    case defaultValue !== undefined: {
      return defaultValue
    }

    default: {
      throw new Error(`Preference with key [${key}] not found `)
    }
  }
}

export function setPreference(key: string, value: any) {
  if (value === undefined) {
    removePreference(key)
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const removePreference = (key: string) => {
  localStorage.removeItem(key)
}
