import React from 'react'
import realtime from '../lib/realtime'
import requests from '../requests'

const UserStatusContext = React.createContext<UserStatus[]>([])

function UserStatusProvider({ children }: React.PropsWithChildren) {
  const [statuses, setStatuses] = React.useState<UserStatus[]>([])

  async function fetchStatus() {
    const statuses = await requests.get('/users-status')
    setStatuses(statuses.data)
  }

  function updateStatus(status: UserStatus) {
    setStatuses((statuses) => {
      const previousIndex = statuses.findIndex((it) => it.user === status.user)
      if (!(previousIndex > -1)) {
        // this could be a new person who joined after the statuses were fetched
        return [...statuses, status]
      }

      statuses.splice(previousIndex, 1, status)
      return [...statuses]
    })
  }

  React.useEffect(() => {
    fetchStatus()

    return realtime.subscribe('users-status/all', {
      onClose() {},
      onError(error: any) {
        console.error('[realtime:users-status]', error)
      },
      onMessage(message) {
        if (message.type === 'update') {
          updateStatus(message.data)
        }
      },
    })
  }, [])

  return (
    <UserStatusContext.Provider value={statuses}>
      {children}
    </UserStatusContext.Provider>
  )
}

export { UserStatusProvider, UserStatusContext }
