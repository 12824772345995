import fetcher from '../lib/fetcher'
import useSWR from 'swr'

// TODO: Implement pagination
// Guide: https://swr.vercel.app/docs/pagination

function useFind(path: string | null, queryParams?: Record<string, any>) {
  const query: any[] = [path]

  if (queryParams) {
    query.push(queryParams)
  }

  const data = useSWR(path ? query : null, fetcher, {
    suspense: true,
  })

  return data
}

export default useFind
