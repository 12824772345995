import endpoints from '../lib/endpoints'
import useFind from './use-find'

function useOrganizationConfig() {
  const { data } = useFind(endpoints.configuration)

  return data as Configuration
}

export default useOrganizationConfig
