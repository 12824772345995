const endpoints = {
  chatStats: '/chat-stats',
  configuration: '/configuration',
  conversation: '/conversations/:conversation',
  conversations: '/conversations',
  file: '/files/:file',
  fileUpdate: '/file-update',
  files: '/files',
  group: '/groups/:group',
  groupCreateRequests: '/group-create-requests',
  groupJoins: '/group-join-requests',
  groupMember: '/group-members/:id',
  groupMembers: '/group-members',
  groupMessages: '/group-messages',
  groups: '/groups',
  lastSeen: '/last-seen',
  login: '/login',
  meeting: '/meetings/:meeting',
  meetings: '/meetings',
  message: '/messages/:message',
  messages: '/messages',
  myPosts: '/posts/me',
  organization: '/organization',
  otp: '/otp',
  post: '/posts/:post',
  postInteraction: '/post-interactions/:id',
  postInteractions: '/post-interactions',
  posts: '/posts',
  unreads: '/unreads',
  user: '/users/:user',
  users: '/users',
  utils: '/utils',
}

function generateEndpoint(
  endpoint: string,
  params: Record<string, string | number | boolean | undefined>
): string {
  let results = endpoint
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      results = results.replace(`:${key}`, value.toString())
    }
  }

  return results
}

export default endpoints

export { generateEndpoint }
