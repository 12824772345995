import Dexie, { Table } from 'dexie'

class Db extends Dexie {
  messages!: Table<Message>

  constructor() {
    super('core')
    this.version(2).stores({
      messages:
        '_id, author, conversation, text, createdAt, media, parentMessage',
    })
  }
}

const db = new Db()

export default db
