interface TopicHandler {
  onClose: VoidFunction
  onError: (error: any) => void
  onMessage: (data: any) => void
}

class Ws {
  url?: string
  ws: WebSocket | null = null

  private handlers: Record<string, Array<TopicHandler>> = {}

  private pendingSubscriptions: [string, TopicHandler][] = []
  private topSubscription: [string, TopicHandler] | null = null

  setUrl(url: string) {
    if (this.ws?.url === url) {
      return
    }

    if (this.ws) {
      this.closeConnection()
      this.ws = null
    }

    this.url = url
  }

  async send(topic: string, data: any) {
    await this.checkConnection()
    this.ws?.send(JSON.stringify({ data, topic, type: 'message' }))
  }

  subscribe(topic: string, handler: TopicHandler) {
    this.queueSubscription(topic, handler)
    return this.unsubscribe.bind(this, topic, handler)
  }

  unsubscribe(topic: string, handler: TopicHandler) {
    const handlers = this.getHandlers(topic)
    const index = handlers.indexOf(handler)

    if (index > -1) {
      handlers.splice(index, 1)
    }
  }

  private async checkConnection(): Promise<void> {
    if (!this.url) {
      throw new Error('No url set. Call .setUrl(url)')
    }

    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      this.ws = new WebSocket(this.url)

      return new Promise((resolve, reject) => {
        this.ws!.onopen = () => {
          this.restoreSubscriptions()

          this.ws!.onmessage = (event) => {
            // we're only streaming text/json data
            const data = JSON.parse(event.data)

            const handlers = this.getHandlers(data.topic)
            for (const handler of handlers) {
              handler.onMessage(data)
            }
          }

          resolve()
        }

        this.ws!.onclose = () => {
          // whenever it close, it should re-open
          this.checkConnection()

          reject(
            new Error('Websocket connection closed. Maybe an error? Check logs')
          )
        }
      })
    }
  }

  private closeConnection() {
    const allHandlers = Object.values(this.handlers).flat()
    for (const handler of allHandlers) {
      handler.onClose()
    }

    this.ws?.close()
    this.handlers = {}
  }

  private getHandlers(topic: string) {
    if (!this.handlers[topic]) {
      this.handlers[topic] = []
    }

    return this.handlers[topic]
  }

  // This is the breakdown for this method. It waits for subscription to complete before proceeding
  // to the next one. This is how it does it.
  // 1. We check if `topSubscription` is truthy. Meaning, there's a subscription going on.
  // 2. Else, we get the next subscription with .shift(), and handle it.
  // 2b. If the top is undefined, we won't handle it _obviously_.
  // 3. Perform the subscription, then recurse.
  private async handleNextSubscription() {
    if (this.topSubscription) {
      return
    }

    this.topSubscription = this.pendingSubscriptions.shift() ?? null
    if (!this.topSubscription) {
      return
    }

    const [topic, handler] = this.topSubscription

    try {
      await this.checkConnection()

      const handlers = this.getHandlers(topic)
      handlers.push(handler)

      this.sendSubscription(topic)

      this.topSubscription = null
    } catch (err) {
      handler.onError(err)
    }

    this.handleNextSubscription()
  }

  queueSubscription(topic: string, handler: TopicHandler) {
    this.pendingSubscriptions.push([topic, handler])
    this.handleNextSubscription()
  }

  private sendSubscription(topic: string) {
    this.ws?.send(
      JSON.stringify({
        topic,
        type: 'subscribe',
      })
    )
  }

  private async restoreSubscriptions() {
    for (const [key] of Object.entries(this.handlers)) {
      if (!key) {
        continue
      }

      this.sendSubscription(key)
    }
  }
}

export default Ws
