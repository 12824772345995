import IndeterminateProgress from './IndeterminateProgress'
import React from 'react'
import styles from './AppSuspense.module.css'

function AppSuspense() {
  return (
    <div className={styles.container}>
      <div className={styles.appSuspense}>
        <IndeterminateProgress />
      </div>
      <h4 className={styles.text}>
        Please hang on, I am working on your request
      </h4>
    </div>
  )
}

export default AppSuspense
