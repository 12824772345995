import axios, { AxiosError, AxiosResponse } from 'axios'
import useAuth from './hooks/use-auth'
import { useEffect } from 'react'

const requests = axios.create({ baseURL: process.env.REACT_APP_API_URL })

const AxiosInterceptor = ({ children }: { children: JSX.Element }) => {
  const { logout } = useAuth()
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response
    }

    const errInterceptor = (error: AxiosError) => {
      if (error.response?.status === 412 || error.message === 'Network Error') {
        const apiUrl = requests.defaults.baseURL
        if (apiUrl) {
          const name = apiUrl.match(/^https?:\/\/([^./]+)\.api/)?.[1]
          logout(name)
        }
      }
      return Promise.reject(error)
    }

    const interceptor = requests.interceptors.response.use(
      resInterceptor,
      errInterceptor
    )

    return () => requests.interceptors.response.eject(interceptor)
  }, [])

  return children
}

export { AxiosInterceptor }
export default requests
