import { Button, ContextMenu, Dialog, useTheme } from '@blackmann/wardrobe'
import {
  ChatBubbleLeftIcon,
  Cog8ToothIcon,
  EnvelopeIcon,
  FolderIcon,
  QueueListIcon,
  UserCircleIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useNavigate } from 'react-router-dom'
import React from 'react'
import ThemeSwitchContextItem from './ThemeSwitchContextItem'
import clsx from 'clsx'
import styles from './NavigationTabs.module.css'
import useAuth from '../hooks/use-auth'
import useOrganizationConfig from '../hooks/use-organization-config'

const tabs = [
  {
    href: '/chat',
    icon: <ChatBubbleLeftIcon />,
    title: 'Chat',
  },
  {
    href: '/repository',
    icon: <FolderIcon />,
    title: 'Repo',
  },
  {
    href: '/meet',
    icon: <VideoCameraIcon />,
    title: 'Meet',
  },
  {
    href: '/feeds',
    icon: <QueueListIcon />,
    title: 'Feed',
  },
]

function NavigationTabs() {
  const { theme, setTheme } = useTheme()
  const { logout } = useAuth()
  const config = useOrganizationConfig()
  const navigate = useNavigate()

  const [showLogoutConfirmation, setShowLogoutConfirmation] =
    React.useState(false)

  function handleMenuItemClick(item: string) {
    switch (item) {
      case 'toggle-theme': {
        setTheme(theme !== 'dark' ? 'dark' : 'light')
        localStorage.setItem('theme', theme !== 'dark' ? 'dark' : 'light')
        break
      }

      case 'use-system-theme': {
        setTheme('system')
        localStorage.setItem('theme', 'system')
        break
      }

      case 'user-profile': {
        navigate('/user-profile')
        break
      }

      case 'logout': {
        setShowLogoutConfirmation(true)
        break
      }
    }
  }

  return (
    <>
      <nav className={styles.tabs}>
        <div>
          <img
            alt="JAVAT365"
            className={styles.brandLogo}
            src="javat-logo.png"
          />
          {tabs.map((tab) => (
            <NavLink
              className={({ isActive }) =>
                clsx(styles.tab, { [styles.active]: isActive })
              }
              key={tab.href}
              to={tab.href}
            >
              {tab.icon}
              {tab.title}
            </NavLink>
          ))}

          {config.domain && (
            <a
              className={styles.tab}
              href="https://mail.javat365.com"
              rel="noreferrer"
              target="_blank"
            >
              <EnvelopeIcon />
              Mail
            </a>
          )}
        </div>
        <div className={styles.lowerTabs}>
          <ContextMenu
            click="left"
            menu={[
              {
                id: 'toggle-theme',
                title: <ThemeSwitchContextItem theme={theme} />,
              },
              {
                disabled: theme === 'system',
                id: 'use-system-theme',
                title: 'Follow system theme',
              },
              {
                id: 'user-profile',
                title: (
                  <div className={styles.profile}>
                    <UserCircleIcon /> View Profile
                  </div>
                ),
              },
              {
                id: 'logout',
                title: (
                  <span className={styles.logoutItem}>
                    Logout from this organization
                  </span>
                ),
              },
            ]}
            onMenuItemClick={handleMenuItemClick}
          >
            <button className={styles.tab}>
              <Cog8ToothIcon />
            </button>
          </ContextMenu>
        </div>
      </nav>

      <Dialog
        className={styles.logoutDialog}
        onClose={() => setShowLogoutConfirmation(false)}
        open={showLogoutConfirmation}
      >
        <div>
          <p>Are you sure you want to log out?</p>

          <footer className={styles.logoutDialogFooter}>
            <Button
              onClick={() => {
                setShowLogoutConfirmation(false)
                logout()
              }}
            >
              Yes, log out
            </Button>
            <Button onClick={() => setShowLogoutConfirmation(false)} outlined>
              Cancel
            </Button>
          </footer>
        </div>
      </Dialog>
    </>
  )
}

export default NavigationTabs
