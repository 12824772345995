import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import React from 'react'
import styles from './ThemeSwitchContextItem.module.css'

interface ThemeSwitchContextItemProps {
  theme: 'light' | 'dark' | 'system'
}

function ThemeSwitchContextItem({ theme }: ThemeSwitchContextItemProps) {
  return (
    <div className={styles.item}>
      {theme !== 'dark' ? <MoonIcon /> : <SunIcon />}
      <span>{theme !== 'dark' ? 'Dark' : 'Light'} theme</span>
    </div>
  )
}

export default ThemeSwitchContextItem
