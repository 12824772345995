import realtime from './realtime'
import requests from '../requests'
import resolveBaseUrl from './resolve-base-url'
import resolveWsUrl from './resolve-ws-url'

function updateRequests(auth?: Auth) {
  const { organization, token, user } = auth || {}

  if (user && organization) {
    requests.defaults.baseURL = resolveBaseUrl(organization.slug)
    requests.defaults.headers['authorization'] = `Bearer ${token}`

    const wsUrl = resolveWsUrl(requests.defaults.baseURL!)
    realtime.setUrl(`${wsUrl}/${token}`)
  } else {
    requests.defaults.baseURL = process.env.REACT_APP_API_URL
  }

  // console.log('[API_URL]', 'Updated url to', requests.defaults.baseURL)
}

export default updateRequests
