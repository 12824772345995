import React from 'react'

function defaultSelector(item: any) {
  return item['_id']
}

function useLookup<T>(
  data: T[],
  idSelector: (item: T) => string = defaultSelector
) {
  return React.useMemo(() => {
    const res: Record<string, T> = {}

    for (const item of data) {
      res[idSelector(item)] = item
    }

    return res
  }, [data, idSelector])
}

export default useLookup
