import axios from 'axios'
import {
  PDF_FILE_FORMATS,
  POWERPOINT_FILE_FORMATS,
  SPREADSHEET_FILE_FORMATS,
  WORD_FILE_FORMATS,
} from '../constants'

export type supportedDocumentType = 'word' | 'pdf' | 'sheet' | 'ppt'

export const getTypeOfFileFormat = (
  name: string
): supportedDocumentType | null => {
  const fileExtension = name
    .split('.')
    [name.split('.').length - 1].toLowerCase()

  return PDF_FILE_FORMATS.includes(fileExtension)
    ? 'pdf'
    : WORD_FILE_FORMATS.includes(fileExtension)
    ? 'word'
    : SPREADSHEET_FILE_FORMATS.includes(fileExtension)
    ? 'sheet'
    : POWERPOINT_FILE_FORMATS.includes(fileExtension)
    ? 'ppt'
    : null
}

export async function getFileFromURL(url: string) {
  // Make a GET request to the URL
  const response = await fetch(url)

  // Check the status code of the response
  if (response.ok) {
    // If the status code is 200, convert the response to a Blob
    const blob = await response.blob()
    return blob
  } else {
    // If the status code is not 200, throw an error
    throw new Error(`Unable to get file from URL: ${response.statusText}`)
  }
}

export function blobToFile(blob: Blob, fileName: string) {
  // Create a new File object
  const file = new File([blob], fileName, { type: blob.type })
  return file
}

export const axiosWithNoAuth = axios.create({
  // ... other options
  headers: {
    // ... other headers
    Authorization: null, // Remove the Authorization header
  },
})
