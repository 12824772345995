import endpoints, { generateEndpoint } from '../lib/endpoints'
import Chat from '../lib/chat'
import { ChatContext } from '../contexts/ChatContext'
import React from 'react'
import dayjs from 'dayjs'
import requests from '../requests'
import useAuth from '../hooks/use-auth'

function getConversationTitle(members: Array<User>) {
  return members.map((member) => member.firstName).join(', ')
}

const UpcommingMeetingNotiferContext = React.createContext<{
  updateMeetings: (meeting: Meeting) => void
  removeMeeting: (meeting: Meeting) => void
}>({
  removeMeeting(meeting) {},
  updateMeetings(meeting) {},
})

// created this notifier as provider to improve performance. if we use use-find here it will consume lot of performance
// so i'm getting here all the meeting only on initial load and after that on meeting pages i'm updating meeting by using
// update meeting and remove meeting
const UpComingMeetingNotifierProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { user } = useAuth()
  const { chats } = React.useContext(ChatContext)
  const [meetings, setMeetings] = React.useState<Meeting[]>([])

  const intervalIdRef = React.useRef<NodeJS.Timer | undefined>(undefined)

  const sendMessageToUser = async (user: User, meeting: Meeting) => {
    const title = getConversationTitle([user])

    const { data } = await requests.post(endpoints.conversations, {
      title,
      type: 'dm',
      users: [user].map((member) => member._id),
    })

    const conversationEndpoint = generateEndpoint(endpoints.conversation, {
      conversation: data._id,
    })
    const { data: conversationDetail } = await requests.get(
      conversationEndpoint,
      { params: { $populate: 'users' } }
    )

    let chat = chats.find(
      (chat) => chat.conversation._id === conversationDetail._id
    )

    if (!chat) {
      const newChat = new Chat({
        author: user!,
        conversation: conversationDetail!,
      })
      newChat.goLive()
      chat = newChat
    }

    if (chat) {
      const meetingLink = `Link: ${meeting.shareLink}`
      const message = `Meeting: "${meeting.title}" has started.`
      chat.reply({
        elements: [
          [{ text: message, type: 'text' } as any],
          [{ text: meetingLink, type: 'text' } as any],
        ],
        files: [],
        mentions: [],
        text: message + ' ' + meetingLink,
      })
    }
  }

  async function sendMeetingNotification() {
    clearTimeout(intervalIdRef.current)
    for (let i = 0; i < meetings.length; i++) {
      const el = meetings[i]
      const meetingDate = dayjs(el.meetingDate).toDate()
      if (
        new Date(meetingDate) <= new Date() &&
        !el.messageSent &&
        (el.host as any) === user?._id
      ) {
        // console.log('Send message....')
        // Remove the element from the array
        const endpoint = generateEndpoint(endpoints.meeting, {
          meeting: el._id,
        })
        await requests.patch(endpoint, { messageSent: true, noEmail: true })
        const { data } = await requests.get(endpoint, {
          params: {
            $links: true,
            '$populate[0]': 'invitedUsers',
            '$populate[1]': 'host',
          },
        })
        const meeting = data as Meeting

        for (let j = 0; j < meeting.invitedUsers.length; j++) {
          const invitedUser = meeting.invitedUsers[j]
          await sendMessageToUser(invitedUser, meeting)
        }

        meetings.splice(i, 1)
      }
    }
    if (meetings.length > 0) {
      intervalIdRef.current = setTimeout(sendMeetingNotification, 10000)
    }
  }

  React.useEffect(() => {
    if (!user) return
    async function fetchMeetings() {
      const topOfTheHour = dayjs().startOf('minute').subtract(0, 'minute')

      const { data: userMeetings } = await requests.get(endpoints.meetings, {
        params: {
          '$or[0][invitedUsers][$in]': user!._id,
          '$or[1][host]': user!._id,
          '$sort[meetingDate]': 1,
          'endDate[$gt]': topOfTheHour.toISOString(),
          'status[$ne]': 'ended',
        },
      })
      
      setMeetings(
        userMeetings.data.filter(
          (el: Meeting<string>) => !el.messageSent && el.host === user?._id
        )
      )
    }
    fetchMeetings()
  }, [user])

  function updateMeetings(meeting: Meeting) {
    const updatedMeetings = [...meetings]
    const isMeetingAlreadyExist = updatedMeetings.find(
      (el) => el._id === meeting._id
    )
    if (!isMeetingAlreadyExist) {
      updatedMeetings.push(meeting)
      setMeetings(updatedMeetings)
      return
    }
    const indexOfMeeting = updatedMeetings.findIndex(
      (el) => el._id === meeting._id
    )
    updatedMeetings[indexOfMeeting] = meeting
    setMeetings(updatedMeetings)
  }

  function removeMeeting(meeting: Meeting) {
    const updatedMeeetings = [...meetings]
    setMeetings(updatedMeeetings.filter((el) => el._id !== meeting._id))
  }

  React.useEffect(() => {
    if (!meetings.length) return
    sendMeetingNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings])

  return (
    <UpcommingMeetingNotiferContext.Provider
      value={{ removeMeeting, updateMeetings }}
    >
      {children}
    </UpcommingMeetingNotiferContext.Provider>
  )
}

export default UpComingMeetingNotifierProvider

export { UpcommingMeetingNotiferContext }
