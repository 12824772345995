import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import EmptyView from './EmptyView'
import React from 'react'
import styles from './RepositoryEmptyView.module.css'

function MeetingEmptyView() {
  return (
    <EmptyView icon={<ArchiveBoxXMarkIcon />}>
      <div className={styles.text}>Create, edit or join a meeting</div>
    </EmptyView>
  )
}

export default MeetingEmptyView
