import AppRoutes from './AppRoutes'
import AppSuspense from './components/AppSuspense'
import { AuthProvider } from './contexts/AuthContext'
import { HashRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import React from 'react'
import { SWRConfig } from 'swr'
import { SnackbarProvider } from './contexts/SnackbarContext'
import SuspenseWithBoundary from './components/SuspenseWithError'
import { ThemeProvider } from '@blackmann/wardrobe'
import localStorageCache from './lib/localstorage-cache'
import { DndProvider } from 'react-dnd'

function App() {
  const cache = localStorageCache('app-cache')

  return (
    <ThemeProvider>
      <SWRConfig value={{ provider: () => cache }}>
        <SuspenseWithBoundary fallback={<AppSuspense />}>
          <AuthProvider>
            <HashRouter>
              <SnackbarProvider>
                <DndProvider backend={HTML5Backend}>
                  <AppRoutes />
                </DndProvider>
              </SnackbarProvider>
            </HashRouter>
          </AuthProvider>
        </SuspenseWithBoundary>
      </SWRConfig>
    </ThemeProvider>
  )
}

export default App
