import React from 'react'

interface ErrorBoundaryProps extends React.PropsWithChildren {
  errorView?: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // TODO:
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error)
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorView
    }

    return this.props.children
  }
}

export default ErrorBoundary
