import { AuthContext } from '../contexts/AuthContext'
import React from 'react'
import resetPreferences from '../lib/reset-preferences'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

const noAuth = {
  organization: undefined,
  token: undefined,
  user: undefined,
}

function useAuth() {
  const { activeProfile, profiles, setActiveProfile, setProfiles } =
    React.useContext(AuthContext)

  const { cache } = useSWRConfig()
  const navigate = useNavigate()

  const activeAuth = activeProfile ? profiles[activeProfile] : noAuth

  const { organization, token, user } = activeAuth

  const addProfile = React.useCallback(
    (auth: Auth) => {
      const slug = auth.organization.slug
      setProfiles({ ...profiles, [slug]: auth })
    },
    [profiles, setProfiles]
  )

  const clear = React.useCallback(() => {
    // REDO: This is not the best in my opinion. We shouldn't clear cache of
    // previous profile on profile switch.
    // But it was hard making SWR cache provider match with the current profile
    // so this is the lazy way.
    ;(cache as Map<string, any>).clear()
    resetPreferences()

    navigate('/')
  }, [cache, navigate])

  const logout = React.useCallback(
    (profile?: string | null) => {
      profile = profile || activeProfile
      if (profile) {
        delete profiles[profile]
        setProfiles({ ...profiles })
        setActiveProfile(Object.keys(profiles)[0] || null)

        clear()
      }
    },
    [activeProfile, clear, profiles, setActiveProfile, setProfiles]
  )

  const removeProfile = React.useCallback(
    (profile: string) => {
      logout(profile)
    },
    [logout]
  )

  const setProfile = React.useCallback(
    (profile: string) => {
      if (activeProfile === profile) {
        return
      }

      setActiveProfile(profile)

      clear()
    },
    [clear, activeProfile, setActiveProfile]
  )

  return {
    addProfile,
    logout,
    organization,
    profiles,
    removeProfile,
    setProfile,
    token,
    user,
  }
}

export default useAuth
