import { Navigate } from 'react-router-dom'
import React from 'react'
import queries from '../../lib/queries'
import useAuth from '../../hooks/use-auth'
import useFind from '../../hooks/use-find'

function RedirectToNextMeeting() {
  const { user } = useAuth()
  const { data } = useFind(...queries.meetings(user!))

  const [firstMeeting] = data.data

  if (!firstMeeting) {
    return null
  }

  return <Navigate replace to={firstMeeting._id} />
}

export default RedirectToNextMeeting
