import { NavigateFunction } from 'react-router-dom'
import { setPreference } from './preferences'

interface BellBoy {
  navigate: NavigateFunction | null
  requestNotificationPermission: () => Promise<void>
  sendNotification: (title: string, options?: NotificationOptions) => void
}

const bellboy: BellBoy = {
  navigate: null,
  async requestNotificationPermission() {
    if ('Notification' in window && Notification.permission !== 'granted') {
      await Notification.requestPermission()
    }
  },
  sendNotification(title: string, options?: NotificationOptions) {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notification = new Notification(title, {
        icon: '/javat-logo.png',
        ...options,
      })
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          // The tab has become visible so clear the now-stale Notification.
          notification.close()
        }
      })
      notification.onclick = () => {
        const destination = notification.data?.destination as string
        // first removing chat cache and then moving to chat page
        if (destination.includes('chat')) {
          sessionStorage.removeItem('chat_url')
        }
        // if message is from other tenant then will move the user to other tenant
        // with given destination
        if (notification.data.tenant) {
          setPreference('activeProfile', notification.data.tenant)
          this.navigate?.(destination)
          window.location.reload()
          return
        }
        if (destination) {
          this.navigate?.(destination)
        }
      }
    }
  },
}

export default bellboy
