import React from 'react'

// Chat

export const Chat = React.lazy(() => import('./pages/chat'))
export const DMChat = React.lazy(
  () => import('./pages/chat/dm/[conversationId]')
)
export const GroupChat = React.lazy(
  () => import('./pages/chat/group/[conversationId]')
)
export const StarredMessages = React.lazy(() => import('./pages/chat/starred'))
export const UnreadChats = React.lazy(() => import('./pages/chat/unreads'))
export const MessagesSearch = React.lazy(() => import('./pages/chat/search'))

// Groups

export const GroupSearch = React.lazy(() => import('./pages/groups/search'))

// Feeds

export const GroupFeeds = React.lazy(() => import('./pages/feeds/[groupId]'))
export const FeedDetail = React.lazy(() => import('./pages/feeds/[feedId]'))
export const Feeds = React.lazy(() => import('./pages/feeds'))
export const StarredPosts = React.lazy(() => import('./pages/feeds/starred'))

export const GroupRepo = React.lazy(
  () => import('./pages/repository/[groupId]')
)

// Repo

export const Repo = React.lazy(() => import('./pages/repository'))

// file editors
export const FileEditor = React.lazy(
  () => import('./pages/repository/FileEditor/[fileId]')
)

// Meet
export const Meet = React.lazy(() => import('./pages/meet'))
export const MeetDetail = React.lazy(() => import('./pages/meet/[meetId]'))
export const MeetEdit = React.lazy(() => import('./pages/meet/[meetId]/edit'))
export const NewMeeting = React.lazy(() => import('./pages/meet/new'))

// Auth
export const Login = React.lazy(() => import('./pages/login'))

export const UserProfile = React.lazy(() => import('./pages/user-profile'))

