import React from 'react'
import queries from '../lib/queries'
import realtime from '../lib/realtime'
import useAuth from '../hooks/use-auth'
import useFind from '../hooks/use-find'
import bellboy from '../lib/bell-boy'

export function getFeedLink(post: Post) {
  return `/feeds/${post._id}`
}

const FeedContext = ({ children }: { children: JSX.Element }) => {
  const { user, organization } = useAuth()
  const { data } = useFind(...queries.groupMemberships(user!._id))
  const [posts, setPosts] = React.useState<Post[]>([])
  const subscribedFeeds: Set<string> = new Set()

  React.useEffect(() => {
    data.data.forEach((member: GroupMembership<Group>) => {
      const feedId = `feed/${member.group._id}`
      if (subscribedFeeds.has(feedId)) return
      realtime.subscribe(feedId, {
        onClose() {},
        onError(error: any) {
          console.error('[realtime:users-feed]', error)
        },
        onMessage(message) {
          if (message.data) {
            const isPostExist = posts.find((el) => el._id === message.data._id)
            if (!isPostExist) {
              setPosts((prevPosts) => [...prevPosts, message.data])

              return bellboy.sendNotification(
                `${message.data.group.title} - ${organization?.title || ''}`,
                {
                  body:
                    'New content is available: ' +
                    message.data.title?.substring(0, 24),
                  data: {
                    destination: getFeedLink(message.data),
                  },
                }
              )
            }
          }
        },
      })
      subscribedFeeds.add(feedId)
    })
  }, [])

  return children
}

export default FeedContext
